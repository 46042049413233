









































































































































































































import { Vue, Component } from 'vue-property-decorator';
import { VueMaskFilter } from 'v-mask';
import dayjs from 'dayjs';
import { inject } from 'inversify-props';
import { AgGridVue } from '@ag-grid-community/vue';
import { SelectionChangedEvent, GridReadyEvent, ValueGetterParams } from '@ag-grid-community/core';
import { isArray } from 'lodash';
import CrmAppBar from '@/components/crm/app-bar.vue';
import DateRangeFilter from '@/components/date-range-filter.vue';
import DateTimePickerField from '@/components/date-time-picker-field.vue';
import { IDateRangeConfig } from '@/interfaces/date-range-config.interface';
import Tooltip from '@/components/tooltip.vue';
import CrmChatCloseSteps from '@/components/crm/chat-close-steps.vue';
import CrmChatCloseForm from '@/components/crm/chat-close-form.vue';
import Field from '@/components/field.vue';
import DataGridFilter from '@/components/data-grid-filter.vue';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import { DateHelper } from '@/utils/helpers/date-helper';
import AgGridWrapper from '@/components/ag-grid-wrapper.vue';
import { IGridConfig } from '@/interfaces/grid-config.interface';
import { IGridCellEvent } from '@/interfaces/grid-cell-clicked.interface';
import { GridHelper } from '@/utils/helpers/grid-helper';
import { IKeyValue } from '@/interfaces/key-value.interface';
import { IDialogConfig } from '@/interfaces/dialog-config.interface';
import ConversationService from '@/services/crm/conversation.service';
import AttendanceModel from '@/models/crm/attendance.model';
import ConversationDepartmentModel from '@/models/crm/conversation-department.model';
import UserModel from '@/models/user.model';
import ActivityService from '@/services/crm/activity.service';
import ConversationModel from '@/models/crm/conversation.model';
import ContentDialog from '@/components/content-dialog.vue';
import CrmChatHistoryMessagesViewer from '../../components/crm/chat-history-messages-viewer.vue';
import ContactTypeModel from '@/models/crm/contact-type.model';
import { ConversationTypeEnum } from '@/enums/crm/conversation-type.enum';
import { ClientTypeEnum } from '@/enums/client-type.enum';
import { PhoneNumberMaskEnum } from '@/enums/phone-number-mask.enum';

type DataGridFilterConfig = {
  client: string[] | null;
  prospect: number[] | null;
  periodType: PeriodTypeEnum;
  period: (Date | null)[];
  contactType: ContactTypeModel | null;
  contactNumber: string[] | null;
  department: ConversationDepartmentModel[] | null;
  attendant: AttendanceModel[] | null;
  lastMessageDateTime: Date | null;
  protocolNumber: string | null;
  contact: string[] | null;
};

enum PeriodTypeEnum {
  OpenDate = 'OPEN_DATE',
  ClosingDate = 'CLOSING_DATE',
}

enum ContactTypesEnum {
  Client = ConversationTypeEnum.Client,
  Prospect = ConversationTypeEnum.Prospect,
}

interface ISelectOption<T> {
  codigo: T;
  descricao: string;
}

@Component({
  components: {
    CrmAppBar,
    CrmChatCloseForm,
    Field,
    DataGridFilter,
    DateRangeFilter,
    DateTimePickerField,
    Tooltip,
    AgGridWrapper,
    AgGridVue,
    ContentDialog,
    CrmChatHistoryMessagesViewer,
    CrmChatCloseSteps,
  },
})
export default class CrmCloseChats extends Vue {
  @inject(InjectionIdEnum.CrmActivityService)
  private activityService!: ActivityService;

  @inject(InjectionIdEnum.CrmConversationService)
  private conversationService!: ConversationService;

  btnSave = false;

  btnEnabled = 0;

  etapa1 = 1;

  etapa2 = 2;

  etapa3 = 3;

  grid: GridReadyEvent | null = null;

  gridSettings: IGridConfig = {
    loading: false,
    defaultSort: [{ colId: 'dataUltimaMensagem', sort: 'desc' }],
    columnDefs: [
      GridHelper.getSelectionColDef(),
      {
        headerName: `${this.$t('crm.view.chats.grid.openDate')}`,
        colId: 'openDate',
        field: 'dataInicio',
        maxWidth: 155,
        sortable: true,
        valueGetter: (params: ValueGetterParams): string => {
          const date = params.data.dataInicio;
          return DateHelper.formatToIsoDateTimeString(date);
        },
        valueFormatter: (params): string => DateHelper.formatToLocale(params.value, 'dateTime'),
        cellClass: 'dateISO',
      },
      {
        headerName: `${this.$t('crm.view.chats.grid.lastAttendance')}`,
        colId: 'lastAttendance',
        field: 'dataUltimaMensagem',
        maxWidth: 155,
        sortable: true,
        valueGetter: (params: ValueGetterParams): string => {
          const date = params.data.dataUltimaMensagem || params.data.dataInicio || params.data.dataInclusao;
          return DateHelper.formatToIsoDateTimeString(date);
        },
        valueFormatter: (params): string => DateHelper.formatToLocale(params.value, 'dateTime'),
        cellClass: 'dateISO',
      },
      {
        headerName: `${this.$t('crm.view.chats.grid.contactType')}`,
        colId: 'contactType',
        field: 'tipo',
        flex: 0.8,
        sortable: true,
        valueGetter: (params: ValueGetterParams): string => {
          const isRowPinned = params.node && params.node.rowPinned;
          if (!isRowPinned) {
            return params.data && params.data.tipo
              && params.data.tipo.toLowerCase().includes(ClientTypeEnum.Client) ? 'Cliente' : 'Prospect';
          }
          return '';
        },
      },
      {
        headerName: `${this.$t('crm.view.chats.grid.legalName')}`,
        colId: 'legalName',
        field: 'cliente.nome',
        flex: 0.8,
        sortable: true,
        valueGetter: (params: ValueGetterParams): string => {
          const isRowPinned = params.node && params.node.rowPinned;
          if (!isRowPinned) {
            if (params.data.prospect) {
              return params.data.prospect.nome;
            }
            if (params.data.cliente) {
              return params.data.cliente.nome;
            }
            return params.data.nome;
          }
          return '';
        },
      },
      {
        headerName: `${this.$t('crm.view.chats.grid.contactName')}`,
        colId: 'contactName',
        field: 'contato.nome',
        flex: 0.8,
        sortable: true,
        valueGetter: (params: ValueGetterParams): string => {
          const isRowPinned = params.node && params.node.rowPinned;
          if (!isRowPinned) {
            if (params.data.contato) {
              return params.data?.contato?.nome;
            }
            if (params.data.nomeContato && params.data.nomeContato.trim() !== '') {
              return params.data?.nomeContato;
            }
            const mask = params.data.numeroWhatsapp && params.data.numeroWhatsapp.length > 12
              ? '+## (##) #####-####' : '+## (##) ####-####';
            return VueMaskFilter(params.data?.numeroWhatsapp, mask);
          }
          return '';
        },
      },
      {
        headerName: `${this.$t('crm.view.chats.grid.protocol')}`,
        colId: 'protocol',
        field: 'protocolo',
        maxWidth: 150,
        sortable: true,
      },
      {
        headerName: `${this.$t('crm.view.chats.grid.attendant')}`,
        colId: 'attendant',
        field: 'atendente.nome',
        flex: 0.5,
        sortable: true,
      },
      {
        headerName: `${this.$t('crm.view.chats.grid.department')}`,
        colId: 'department',
        field: 'departamento.nome',
        flex: 0.5,
        sortable: true,
      },
    ],
  };

  items: ConversationModel[] = [];

  selected: ConversationModel[] = [];

  selectedFromFilter: ConversationModel[] = [];

  closedIds: number[] = [];

  // #region filters

  predefinedPeriodRanges: IDateRangeConfig[] = this.getDateRanges();

  multipleFilterChanged = false;

  filters: DataGridFilterConfig = {
    client: null,
    prospect: null,
    periodType: PeriodTypeEnum.OpenDate,
    period: [],
    contactType: null,
    contactNumber: null,
    department: null,
    attendant: null,
    lastMessageDateTime: null,
    protocolNumber: null,
    contact: null,
  };

  periodTypeOptions: ISelectOption<PeriodTypeEnum>[] = [];

  contactTypeOptions: ISelectOption<ContactTypesEnum>[] = [];

  contactNumberOptions: string[] = [];

  departmentOptions: ConversationDepartmentModel[] = [];

  contactOptions: string[] = [];

  attendantOptions: UserModel[] = [];

  phoneMaskOptions = PhoneNumberMaskEnum;

  // #endregion

  dialogConfig: IKeyValue<IDialogConfig> = {
    history: {
      show: false,
      conversation: null,
    },
    chatsClose: {
      show: false,
    },
  };

  async created(): Promise<void> {
    const loader = this.$loading.show();
    try {
      await this.loadItems();
      await this.loadFilterOptions();
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }
  }

  async onCellClick(event: IGridCellEvent<ConversationModel>): Promise<void> {
    if (this.selected.includes(event.data)) {
      this.selected.splice(Number(event.node.rowIndex), 1);
      event.node.setSelected(false);
    } else {
      this.selected.push(event.data);
      event.node.setSelected(true);
    }
  }

  onSelectionChanged(change: SelectionChangedEvent, selected: ConversationModel[]): void {
    this.selected = selected;
  }

  onExport(selected: ConversationModel[]): void {
    if (this.grid) {
      const onlySelected = !!selected.length && this.items.length !== selected.length;
      const columnKeys = this.gridSettings.columnDefs
        .filter((x) => !x.checkboxSelection)
        .map((x) => x.colId || x.field || '');

      this.grid.api.exportDataAsExcel({
        onlySelected,
        columnKeys,
        allColumns: true,
        author: 'IBtech',
        sheetName: 'Conversas',
        fileName: ConversationService.generateConversationReportExportFilename(new Date()),
      });
    }
  }

  onAfterDeleteChats(result: number[]): void {
    this.closedIds = result;
  }

  get filteredItems(): ConversationModel[] {
    if (this.closedIds.length > 0) {
      this.items = this.items.filter((item) => !this.closedIds.includes(item.id));
    }
    let itemsAux = this.items;

    if (this.filters.period && this.filters.period[0]) {
      const start = dayjs(this.filters.period[0]);
      itemsAux = itemsAux.filter(
        (item) => item.dataInicio !== null
          && (dayjs(item.dataInicio).isAfter(start) || dayjs(item.dataInicio).isSame(start)),
      );
    }

    if (this.filters.period && this.filters.period[1]) {
      const end = dayjs(this.filters.period[1]);
      itemsAux = itemsAux.filter(
        (item) => item.dataFechamento !== null
          && (dayjs(item.dataInicio).isBefore(end) || dayjs(item.dataInicio).isSame(end)),
      );
    }

    if (this.filters.contactType !== null) {
      itemsAux = this.items.filter((item) => this.filters
        .contactType?.descricao.toLowerCase().includes(item.tipo.toLowerCase()));
    }

    const contacts = this.filters.contact !== null ? this.filters.contact : [];

    if (contacts.length > 0) {
      itemsAux = this.items.filter((item) => (item.contato && item.contato.nome
        && contacts.filter((contact) => contact.toLowerCase().includes(
          item.contato.nome.toLowerCase(),
        )).length > 0)
        || (item.nomeContato && contacts.filter((contact) => contact.toLowerCase()
          .includes(item.nomeContato.toLowerCase())).length > 0)
        || (item.numeroWhatsapp && contacts.filter((contact) => contact.toLowerCase()
          .includes(item.numeroWhatsapp.toLowerCase())).length > 0));
    }

    const contactNumbers = this.filters.contactNumber !== null ? this.filters.contactNumber : [];
    if (contactNumbers.length > 0) {
      itemsAux = this.items.filter((item) => item.numeroWhatsapp
        && contactNumbers.filter((number) => item.numeroWhatsapp.includes(number)).length > 0);
    }

    const departments = this.filters.department !== null ? this.filters.department : [];
    if (departments.length > 0) {
      itemsAux = this.items.filter((item) => departments.filter((department) => item.departamento
        && item.departamento.id && item.departamento.id === Number(department)).length > 0);
    }

    const attendants = this.filters.attendant !== null ? this.filters.attendant : [];
    if (attendants.length > 0) {
      itemsAux = this.items.filter((item) => attendants.filter((attendant) => item.atendente
        && item.atendente.id && item.atendente.id === Number(attendant)).length > 0);
    }

    if (this.filters.protocolNumber) {
      const protocol = this.filters.protocolNumber?.toString();
      itemsAux = this.items.filter((item) => item.protocolo.toString().includes(protocol));
    }

    if (this.filters.lastMessageDateTime) {
      itemsAux = this.items.filter((item) => item.dataUltimaMensagem
        && dayjs(item.dataUltimaMensagem.toString()).set('second', 0)
          .toString().includes(dayjs(this.filters.lastMessageDateTime).toString()));
    }

    return itemsAux;
  }

  get activeFilters(): number {
    let active = 0;
    const filtersToIgnore = ['periodType'];
    const filterKeys = Object.keys(this.filters);

    filterKeys.forEach((key) => {
      const filter = this.filters[key];
      switch (key) {
        default:
          if (!filtersToIgnore.includes(key) && filter && !(isArray(filter) && !filter.length)) {
            active += 1;
          }
      }
    });

    return active;
  }

  get filtered(): boolean {
    if (this.filters.period.length !== 0 || this.filters.contactType !== null
      || (this.filters.contact !== null && this.filters.contact.length > 0)
      || (this.filters.contactNumber !== null && this.filters.contactNumber.length > 0)
      || (this.filters.department !== null && this.filters.department.length > 0)
      || (this.filters.attendant !== null && this.filters.attendant.length > 0)
      || this.filters.protocolNumber !== null
      || this.filters.lastMessageDateTime !== null) {
      return true;
    }
    return false;
  }

  get viewTitle(): string {
    return this.$t('crm.view.closeChats.title').toString();
  }

  get openDate(): string {
    return this.$t('crm.view.closeChats.filter.openDate').toString();
  }

  get lastMessageDate(): string {
    return this.$t('crm.view.closeChats.filter.lastMessageDate').toString();
  }

  get warningApplyFilters(): string {
    return this.$t('crm.view.closeChats.warningApplyFilters').toString();
  }

  get closeReview(): string {
    return this.$t('crm.view.closeChats.closeReview').toString();
  }

  get classifyClosingConversations(): string {
    return this.$t('crm.view.closeChats.classifyClosingConversations').toString();
  }

  get confirmBeforeClose(): string {
    return `${this.$t('crm.view.closeChats.confirmBeforeClose')}`;
  }

  get warningBeforeClose(): string {
    const conversations = this.selected.length.toString();
    return `${this.$t('crm.view.closeChats.warningBeforeClose', { conversations })}`;
  }

  get warningContinue(): string {
    return `${this.$t('crm.view.closeChats.warningContinue')}`;
  }

  getTelephoneMask(phoneNumber: string) {
    if (phoneNumber.length > 12) {
      return this.phoneMaskOptions.CountryCode9Digits;
    }
    if (phoneNumber.length === 12) {
      return this.phoneMaskOptions.CountryCode8Digits;
    }
    if (phoneNumber.length > 10 && phoneNumber.length < 12) {
      return this.phoneMaskOptions.NoCountryCode9Digits;
    }
    return this.phoneMaskOptions.NoCountryCode8Digits;
  }

  private async loadItems(): Promise<void> {
    this.gridSettings.loading = true;
    this.items = await this.conversationService.getOpenConversations();
    this.gridSettings.loading = false;
  }

  private async loadFilterOptions(): Promise<void> {
    this.periodTypeOptions = this.getPeriodTypes();
    this.contactTypeOptions = this.getContactTypes();
    this.contactOptions = this.getContacts();
    this.contactNumberOptions = this.getContactNumbers();

    const tasks = [
      this.getDepartments().then((result) => {
        this.departmentOptions = result;
      }),
      this.getAttendants().then((result) => {
        this.attendantOptions = result;
      }),
    ];

    await Promise.all(tasks);
  }

  private getPeriodTypes(): ISelectOption<PeriodTypeEnum>[] {
    return [
      {
        codigo: PeriodTypeEnum.OpenDate,
        descricao: this.$t('crm.view.chats.filter.openDate').toString(),
      },
      {
        codigo: PeriodTypeEnum.ClosingDate,
        descricao: this.$t('crm.view.chats.filter.closingDate').toString(),
      },
    ];
  }

  private getContactTypes(): ISelectOption<ContactTypesEnum>[] {
    return [
      {
        codigo: ContactTypesEnum.Client,
        descricao: this.$t('crm.view.closeChats.client').toString(),
      },
      {
        codigo: ContactTypesEnum.Prospect,
        descricao: this.$t('crm.view.closeChats.prospect').toString(),
      },
    ];
  }

  private async getDepartments(): Promise<ConversationDepartmentModel[]> {
    return (await this.conversationService.getDepartments()).filter((department) => department.nome);
  }

  private async getAttendants(): Promise<UserModel[]> {
    return (await this.activityService.getAttendants()).filter((attendant) => attendant.nome);
  }

  private getContacts(): string[] {
    return [...new Set(this.items.filter((conversation) => (conversation.contato && conversation.contato.nome)
    || conversation.nomeContato || conversation.numeroWhatsapp)
      .map((conversation) => (conversation.contato && conversation.contato.nome) || conversation.nomeContato
    || conversation.numeroWhatsapp))];
  }

  private getContactNumbers(): string[] {
    return [...new Set(this.items.filter((conversation) => conversation.numeroWhatsapp)
      .map((conversation) => conversation.numeroWhatsapp))];
  }

  private getDateRanges(): IDateRangeConfig[] {
    return [
      {
        name: `${this.$t('global.today')}`,
        ...DateHelper.getTodayPeriod(),
      },
      {
        name: `${this.$t('global.yesterday')}`,
        ...DateHelper.getYesterdayPeriod(),
      },
      {
        name: `${this.$t('global.currentMonth')}`,
        ...DateHelper.getCurrentMonthPeriod(),
      },
      {
        name: `${this.$t('global.lastMonth')}`,
        ...DateHelper.getLastMonthsPeriod(1),
      },
      {
        name: `${this.$t('global.lastThreeMonths')}`,
        ...DateHelper.getLastMonthsPeriod(3),
      },
      {
        name: `${this.$t('global.lastSixMonths')}`,
        ...DateHelper.getLastMonthsPeriod(6),
      },
      {
        name: `${this.$t('global.lastYear')}`,
        ...DateHelper.getLastYearsPeriod(1),
      },
      {
        name: `${this.$t('global.currentYear')}`,
        ...DateHelper.getCurrentYearPeriod(),
      },
    ];
  }
}
